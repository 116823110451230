'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.TextCollapse = function TextCollapse() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $buttons = [].slice.call(document.querySelectorAll('.see-more-button'));
    var $texts = [].slice.call(document.querySelectorAll('.cta001-text'));

    var clicked = [];
    var height = '90px';

    $buttons.forEach(function ($button, i) {
      clicked[i] = false;

      $button.addEventListener('click', function () {
        if (!clicked[i]) {
          $texts[i].style.height = 'auto';
          $button.innerHTML = $button.getAttribute("data-text-close");
          clicked[i] = true;
        } else if (clicked[i]) {
          $texts[i].style.height = height;
          $button.innerHTML = $button.getAttribute("data-text-open");
          clicked[i] = false;
        }
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();