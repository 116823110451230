'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.NumberAnimating = function NumberAnimating() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      console.log('[init Waypoint.js] already inited');
      return false;
    }

    inited = true;

    var $target = document.querySelectorAll('[data-animate]');
    var $block = document.querySelector('.number-animating-waypoint');

    if ($block) {
      var waypoint = new Waypoint({
        element: $block,
        handler: function handler(direction) {

          if (direction == "down") {
            for (var i = 0; i < $target.length; i++) {

              var numAnim = new CountUp($target[i], 0, $target[i].getAttribute("data-animate"), 0, 3);

              if (!numAnim.error) {
                numAnim.start();
              } else {
                console.error(numAnim.error);
              }
            }
          } else if (direction == "up") {
            for (var _i = 0; _i < $target.length; _i++) {

              var _numAnim = new CountUp($target[_i]);
              _numAnim.reset();
            }
          }
        },
        offset: '60%'
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();