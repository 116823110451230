'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.TeamGrid003Carousel = function TeamGrid003Carousel() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $sliders = [].slice.call(document.querySelectorAll('.teamGrid003-carousel'));
    var $slidersNav = [].slice.call(document.querySelectorAll('.teamGrid003-sliderNav-carousel'));

    if ($sliders) {

      setTimeout(function () {
        $sliders.forEach(function ($slide) {
          var flkty = new Flickity($slide, {
            cellAlign: 'left',
            autoPlay: false,
            prevNextButtons: false,
            pauseAutoPlayOnHover: true,
            pageDots: false,
            wrapAround: true,
            lazyLoad: true
          });
          flkty.on('staticClick', function (event, pointer, cellElement, cellIndex) {
            if (typeof cellIndex == 'number') {
              flkty.selectCell(cellIndex);
            }
          });
        });

        $slidersNav.forEach(function ($sliderNav, i) {
          var flkty = new Flickity($sliderNav, {
            asNavFor: $sliders[i],
            pageDots: false,
            prevNextButtons: false,
            cellAlign: 'left',
            contain: true,
            wrapAround: true
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();