// // On Vue Ready
// loadjs.ready(['vue', 'axios'], {
//   success: function() {


//     // Create our Vue component
//     var GroupNamesFilter = new Vue({
//       el: '#group-name-filters',
//       components: {
//       },
//       delimiters: ['${', '}'],
//       data: {
//         currentGroup: "all"
//       },
//       methods: {
//         filterByGroup: function (groupName) {

//           this.currentGroup = groupName;

//           if (groupName === 'all') {
//             // Reset active sate
//             [].slice.call(document.querySelectorAll(`[data-group-name]`)).forEach(function (item) {
//               item.classList.add('is-active');
//             });

//             return false;
//           }

//           // Reset active sate
//           [].slice.call(document.querySelectorAll(`[data-group-name]`)).forEach(function (item) {
//             item.classList.remove('is-active');
//           });

//           // Set current active state
//           [].slice.call(document.querySelectorAll(`[data-group-name="${groupName}"]`)).forEach(function (item) {
//             item.classList.add('is-active');
//           });
//         }
//       },
//       mounted: function() {
//         setTimeout(() => {
//           // Reset active sate
//           [].slice.call(document.querySelectorAll(`[data-group-name]`)).forEach(function (item) {
//             item.classList.add('site-toogle', 'is-active');
//           });
//         }, 2000);
//       }
//     });


//   }
// });
"use strict";