'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Slider004 = function Slider004() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = [].slice.call(document.querySelectorAll('.slider004-carousel'));
    console.log($slider);

    if ($slider) {

      setTimeout(function () {
        $slider.forEach(function ($slide) {
          var flkty = new Flickity($slide, {
            cellAlign: 'center',
            autoPlay: false,
            pageDots: true,
            wrapAround: true,
            lazyLoad: true,
            prevNextButtons: true
          });
          flkty.on('staticClick', function (event, pointer, cellElement, cellIndex) {
            if (typeof cellIndex == 'number') {
              flkty.selectCell(cellIndex);
            }
          });

          var $buttons = [].slice.call(document.querySelectorAll('.slider004-carousel .flickity-button'));
          var $dots = document.querySelector('.slider004-carousel .flickity-page-dots');
          console.log($dots);
          $buttons.forEach(function ($button) {
            if (flkty.cells.length <= 1) {
              $button.style.display = 'none';
              $dots.style.display = 'none';
            }
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();