'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.SplitCarousel = function SplitCarousel() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = [].slice.call(document.querySelectorAll('.split-carousel'));

    if ($slider) {

      setTimeout(function () {
        $slider.forEach(function ($slide) {
          var flkty = new Flickity($slide, {
            cellAlign: 'center',
            autoPlay: 6000,
            pageDots: true,
            wrapAround: true,
            imagesLoaded: true,
            lazyLoad: true,
            prevNextButtons: true
          });

          var $buttons = [].slice.call(document.querySelectorAll('.split006 .flickity-button'));
          var $dots = document.querySelector('.split006 .flickity-page-dots');

          $buttons.forEach(function ($button) {
            if (flkty.cells.length <= 1) {
              $button.style.display = 'none';
              $dots.style.display = 'none';
            }
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();