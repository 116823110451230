'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.videoSlider003 = function videoSlider003() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = document.querySelector('.video003-slider');

    if ($slider) {
      setTimeout(function () {

        var flkty = new Flickity($slider, {
          cellAlign: 'center',
          autoPlay: false,
          pageDots: false,
          wrapAround: true,
          lazyLoad: true,
          prevNextButtons: true
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();