'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Portfolio001Carousel = function Portfolio001Carousel() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = [].slice.call(document.querySelectorAll('.portfolio001-carousel'));

    if ($slider) {
      setTimeout(function () {
        $slider.forEach(function ($slide) {
          var flkty = new Flickity($slide, {
            cellAlign: 'center',
            pageDots: false,
            wrapAround: true,
            autoPlay: false,
            prevNextButtons: true
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();