'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.ContactForm = function ContactForm() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var $contactButtons = [].slice.call(document.querySelectorAll('[href="#contact"]'));
  var headerEl = document.querySelector('.main-header');
  var topBannerEl = document.querySelector('.top-banner');
  var overlaylEl = document.querySelector('[data-modal-overlay-contact]');
  var modalEl = document.querySelector('[data-modal-js-contact]');
  // const modalOpenLink = document.querySelector('[data-modal-open]');
  var modalCloseLink = document.querySelector('[data-modal-close-contact]');
  var $form = document.querySelector('[contact-form]');

  console.log('salut', $form);

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    $contactButtons.forEach(function ($contactButton) {

      $contactButton.addEventListener('click', function (e) {
        e.preventDefault();

        openWindow();
        // modalEl.classList.add('is-open');
        // overlaylEl.classList.add('is-active');
        // document.querySelector('body').style.overflow = 'hidden';
      });
    });

    if (modalCloseLink) {
      modalCloseLink.addEventListener('click', function (e) {
        e.preventDefault();

        modalEl.classList.remove('is-open');
        overlaylEl.classList.remove('is-active');
        document.querySelector('body').style.overflow = 'auto';
      });
    }

    if (document.querySelector('.form-input-file')) {
      document.querySelector('.form-input-file').addEventListener('change', function () {
        var $input = document.querySelector('.form-input-file');

        setTimeout(function () {
          document.querySelector('.js-file-input').querySelector('p').innerHTML = $input.value.replace(/([^\\]*\\)*/, '');
        }, 0);
      });
    };

    $form.addEventListener('submit', function (e) {
      sessionStorage.setItem("reloading", "true");
    });

    var openWindow = function openWindow() {
      console.log('welcome back !');
      modalEl.classList.add('is-open');
      overlaylEl.classList.add('is-active');
      document.querySelector('body').style.overflow = 'hidden';
    };

    window.onload = function () {
      var reloading = sessionStorage.getItem("reloading");
      if (reloading) {
        sessionStorage.removeItem("reloading");
        openWindow();
      }
    };

    var reloadP = function reloadP() {
      sessionStorage.setItem("reloading", "true");
      document.location.reload();
    };

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();