'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Slider003CarouselRotate = function Slider003CarouselRotate() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = [].slice.call(document.querySelectorAll('.slider-rotate'));

    if ($slider) {

      setTimeout(function () {
        $slider.forEach(function ($slide) {
          var flkty = new Flickity($slide, {
            cellAlign: 'left',
            autoPlay: false,
            pageDots: false,
            prevNextButtons: true,
            draggable: true,
            watchCSS: true,
            wrapAround: true
          });
          flkty.on('staticClick', function (event, pointer, cellElement, cellIndex) {
            if (typeof cellIndex == 'number') {
              flkty.selectCell(cellIndex);
            }
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();