'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.TopBanner = function TopBanner() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var headerEl = document.querySelector('.main-header');
  var topBannerEl = document.querySelector('.top-banner');
  var overlaylEl = document.querySelector('[data-modal-overlay]');
  var modalEl = document.querySelector('[data-modal-js]');
  var modalOpenLink = document.querySelector('[data-modal-open]');
  var modalCloseLink = [].slice.call(document.querySelectorAll('[data-modal-close]'));

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (modalOpenLink) {
      modalOpenLink.addEventListener('click', function (e) {
        e.preventDefault();

        if (modalEl && overlaylEl) {
          modalEl.classList.add('is-open');
          overlaylEl.classList.add('is-active');
          document.querySelector('body').style.overflow = 'hidden';
        }
      });
    }

    if (document.querySelector('.form-input-file')) {
      document.querySelector('.form-input-file').addEventListener('change', function () {
        var $input = document.querySelector('.form-input-file');

        setTimeout(function () {
          document.querySelector('.js-file-input').querySelector('p').innerHTML = $input.value.replace(/([^\\]*\\)*/, '');
        }, 0);
      });
    };

    if (modalCloseLink.length > 0) {
      modalCloseLink.forEach(function (element) {
        element.addEventListener('click', function (e) {
          e.preventDefault();

          if (element.dataset.modalClose == 'banner') {
            topBannerEl.classList.remove('is-active');
            headerEl.classList.add('on-top');

            localStorage.setItem('topBanner', 'accepted');
          } else if (element.dataset.modalClose == 'modal') {
            modalEl.classList.remove('is-open');
            overlaylEl.classList.remove('is-active');
            document.querySelector('body').style.overflow = 'auto';
          }
        });
      });
    }

    if (topBannerEl) {
      if (localStorage.getItem('topBanner') !== 'accepted') {
        topBannerEl.classList.add('is-active');
        headerEl.classList.remove('on-top');
      } else {
        topBannerEl.classList.remove('is-active');
        headerEl.classList.add('on-top');
      }
    };

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();