'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.GeoMap = function GeoMap() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var map = void 0;
  var mapMarkers = {};

  var $ajaxZone = document.querySelector('[data-ajax-refresh]');
  var thumbs = [].slice.call(document.querySelectorAll('[data-place]'));
  var mapStyle = [{
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [{
      "saturation": "-100"
    }]
  }, {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "poi",
    "elementType": "labels.text.stroke",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "road",
    "elementType": "labels.text",
    "stylers": [{
      "color": "#545454"
    }]
  }, {
    "featureType": "road",
    "elementType": "labels.text.stroke",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [{
      "saturation": "-87"
    }, {
      "lightness": "-40"
    }, {
      "color": "#ffffff"
    }]
  }, {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.fill",
    "stylers": [{
      "color": "#f0f0f0"
    }, {
      "saturation": "-22"
    }, {
      "lightness": "-16"
    }]
  }, {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "road.highway.controlled_access",
    "elementType": "labels.icon",
    "stylers": [{
      "visibility": "on"
    }]
  }, {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [{
      "saturation": "-52"
    }, {
      "hue": "#00e4ff"
    }, {
      "lightness": "-16"
    }]
  }];

  var mapIsDoneLoading = false;
  var idleTriggeredCount = 0;

  // Geo Config
  var config = {
    enableHighAccuracy: true,
    timeout: 300000,
    maximumAge: 0
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    return true;
  };

  /**
   * Get Bounds of a given map
   * Returns the min lat, max lat, min lng and max lng points
   * @private
   */
  var getMapBounds = function getMapBounds(mapElement) {
    var mapBounds = mapElement.getBounds();
    // console.log(mapBounds, mapBounds);
    // console.log(mapBounds.ga);
    // console.log(mapBounds.ma);

    // let ex1 = (typeof mapBounds.ma === 'undefined') ? mapBounds.na : mapBounds.ma;
    // let ex2 = (typeof mapBounds.ga === 'undefined') ? mapBounds.ia : mapBounds.ga;

    // var bounds = mapElement.getBounds();
    var ne = mapBounds.getNorthEast();
    var sw = mapBounds.getSouthWest();

    // console.log(mapBounds, ne, sw, ne.lat(), ne.lat);

    return {
      'min_lat': sw.lat(),
      'max_lat': ne.lat(),
      'min_lng': sw.lng(),
      'max_lng': ne.lng()
    };
  };

  /**
   * Get Bounds Zoom Level.
   * @private
   */
  var getBoundsZoomLevel = function getBoundsZoomLevel(getBounds, mapDim) {
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 15;

    function latRad(lat) {
      var sin = Math.sin(lat * Math.PI / 180);
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = getBounds.getNorthEast();
    var sw = getBounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  /**
   * Handle Location Error.
   * @private
   */
  var handleLocationError = function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ? 'Error: The Geolocation service failed.' : 'Error: Your browser doesn\'t support geolocation.');
    infoWindow.open(map);
  };

  /**
   * Create Bounds For Markers.
   * @private
   */
  // const createBoundsForMarkers = (markers) => {
  //   var partialsBounds = new google.maps.LatLngBounds();
  //   markers.forEach((marker) => {
  //     partialsBounds.extend(marker.getPosition());
  //   });
  //   return bounds;
  // };

  /**
   * Initializes the map.
   * @public
   */
  var initMap = function initMap() {

    var $map = document.querySelector('.geomap');

    // Abort if no map on page
    if (!$map) {
      return false;
    };

    // Map is there, we can proceed

    // Init user geo
    initGeolocation();

    var map = new google.maps.Map($map, {
      // disableDefaultUI: true,
      styles: mapStyle,
      center: { lat: 45.507039, lng: -73.556793 },
      zoom: 14
    });

    var bounds = new google.maps.LatLngBounds();

    // var infowindow =  new google.maps.InfoWindow({
    //   content: 'Hello World!',
    //   map: map,
    //   position: {lat: 45.507039, lng: -73.556793}
    // });

    function CustomMarker(latlng, map, args) {
      this.latlng = latlng;
      this.args = args;
      this.setMap(map);
    }

    CustomMarker.prototype = new google.maps.OverlayView();

    CustomMarker.prototype.draw = function () {

      var self = this;

      var div = this.div;

      if (!div) {

        div = this.div = document.createElement('div');

        div.className = 'custom-marker';

        if (typeof self.args.marker_id !== 'undefined') {
          div.dataset.marker_id = self.args.marker_id;
        }

        google.maps.event.addDomListener(div, "click", function (event) {
          // alert('You clicked on a custom marker!');
          removeForceActive();
          div.classList.toggle('force-active');
          document.querySelector('[data-place][data-id="' + event.target.dataset.marker_id + '"]').classList.toggle('force-active');

          var myElement = document.querySelector('[data-place][data-id="' + event.target.dataset.marker_id + '"]');
          var topPos = myElement.offsetTop;
          document.querySelector('.geomap-infos').scroll({
            top: topPos - 25,
            behavior: "smooth"
          });

          google.maps.event.trigger(self, "click");
        });

        google.maps.event.addDomListener(div, "mouseover", function (event) {
          var nodeName = document.createTextNode(document.querySelector('[data-place][data-id="' + event.target.dataset.marker_id + '"]').getAttribute('data-address-text'));
          var addressComponent = document.createElement('div');
          addressComponent.appendChild(nodeName);
          div.appendChild(addressComponent);
          // infowindow.open(map, div);
          document.querySelector('[data-place][data-id="' + event.target.dataset.marker_id + '"]').classList.add('is-active');
        });

        google.maps.event.addDomListener(div, "mouseleave", function (event) {
          div.classList.remove('is-active');
          var childToRemove = div.querySelector('div');
          if (childToRemove) {
            div.removeChild(childToRemove);
          }
          // infowindow.close();
          removeActive();
        });

        var panes = this.getPanes();
        panes.overlayImage.appendChild(div);
      }

      var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

      if (point) {
        div.style.left = point.x + 'px';
        div.style.top = point.y + 'px';
      }
    };

    CustomMarker.prototype.remove = function () {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    };

    CustomMarker.prototype.getPosition = function () {
      return this.latlng;
    };

    var locations = [].slice.call(document.querySelectorAll('[data-place]'));
    locations.forEach(function (i, index) {
      var lat = parseFloat(i.dataset.lat);
      var lng = parseFloat(i.dataset.lng);
      var id = i.dataset.id;

      if (lat && lng) {

        var marker = new google.maps.Marker({
          position: { lat: lat, lng: lng },
          icon: '/img/site/ghost-pin.png',
          map: map
        });

        var customMarker = new CustomMarker(new google.maps.LatLng(lat, lng), map, { 'marker_id': '' + i.dataset.lat + i.dataset.lng + '' });

        mapMarkers[id] = customMarker;

        bounds.extend(marker.position);
      };
    });

    map.fitBounds(bounds);

    // Places thumbs hover class
    $ajaxZone.on('click', function (e) {
      var element = e.target;

      if (element.tagName !== 'ARTICLE') {
        element = element.parentNode;
      };

      var elementLat = element.dataset.lat;
      var elementLng = element.dataset.lng;
      var elementId = element.dataset.id;

      mapMarkers[elementId].div.click();
    });

    $ajaxZone.on('mouseover', function (e) {
      var element = e.target;

      if (element.tagName !== 'ARTICLE') {
        element = element.parentNode;
      };

      removeActive();

      var elementLat = element.dataset.lat;
      var elementLng = element.dataset.lng;
      var elementId = element.dataset.id;

      if (elementId) {
        // console.log("mapMarkers[elementId]", mapMarkers[elementId].div);
        mapMarkers[elementId].div.classList.add('is-active');
      };

      element.classList.add('is-active');
    });

    $ajaxZone.on('mouseleave', function (e) {
      removeActive();
    });

    if (document.querySelector('.geoForm')) {
      document.querySelector('.geoForm').addEventListener('submit', function (e) {
        e.preventDefault();
        orderLocationsByDistanceInput(document.querySelector('.geoForm [name="postalCode"]').value);
      });
    };

    //(optional) restore the zoom level after the map is done scaling
    var idleListener = google.maps.event.addListener(map, "idle", function () {
      map.setZoom(getBoundsZoomLevel(bounds, { height: $map.offsetHeight, width: $map.offsetWidth }) - 1);
      google.maps.event.removeListener(idleListener);
      mapIsDoneLoading = true;
    });

    // on Map Drag end();
    // var dragendListener = google.maps.event.addListener(map, 'dragend', function() {
    //   //
    //   // console.log(getMapBounds(map));

    //   if (mapIsDoneLoading && idleTriggeredCount) {
    //     fetchPlacesByBounds(getMapBounds(map));
    //   } else {
    //     if (mapIsDoneLoading) {
    //       idleTriggeredCount = true;
    //     };
    //   }
    // });

    // on Map zoom changed();
    var zoomchangedListener = google.maps.event.addListener(map, 'idle', function () {
      //
      // console.log(getMapBounds(map));

      console.log("IDLE Triggered");

      // if (mapIsDoneLoading && idleTriggeredCount > 2) {
      //   fetchPlacesByBounds(getMapBounds(map));
      // } else {
      //   if (mapIsDoneLoading) {
      //     idleTriggeredCount++;
      //   };
      // }
    });

    var fetchPlacesByBounds = function fetchPlacesByBounds(bounds) {

      $('[data-ajax-refresh]')[0].innerHTML = '<img class="svg-loader" src="/img/site/svg-loader.svg" />';

      var fetchUrl = '?minLat=' + bounds.min_lat + '&maxLat=' + bounds.max_lat + '&minLng=' + bounds.min_lng + '&maxLng=' + bounds.max_lng;

      console.log("window.location.href === ", window.location.href);

      // If we are already filterin by one Categorie or more...
      var weAreFilteringByCategories = window.location.href.indexOf('?') !== -1;

      console.log("weAreFilteringByCategories === ", weAreFilteringByCategories);

      if (weAreFilteringByCategories) {
        fetchUrl = window.location.href + '&minLat=' + bounds.min_lat + '&maxLat=' + bounds.max_lat + '&minLng=' + bounds.min_lng + '&maxLng=' + bounds.max_lng;
      };

      fetch(fetchUrl).then(function (resp) {
        return resp.text();
      }).then(function (data) {

        window.dataLayer = window.dataLayer || [];

        // Datalayer - Form Homepage & Depositaires
        dataLayer.push({
          'event': 'formSubmission',
          'category': 'code-postal-location',
          'action': 'send',
          'label': 'form'
        });

        // Fetch and transform response to an HTML object
        var htmlResponse = document.createElement('div');
        htmlResponse.innerHTML = data;

        // Append new Ajax content into container
        var load = htmlResponse.querySelector('[data-ajax-refresh]').innerHTML;
        $('[data-ajax-refresh]')[0].innerHTML = load;

        $('[data-ajax-pagination]')[0].style.display = 'none';

        setTimeout(function () {

          // Once refreshed...
          var locations = [].slice.call(document.querySelectorAll('[data-place]'));
          locations.forEach(function (i, index) {
            var lat = parseFloat(i.dataset.lat);
            var lng = parseFloat(i.dataset.lng);
            var id = i.dataset.id;

            if (lat && lng) {

              var marker = new google.maps.Marker({
                position: { lat: lat, lng: lng },
                icon: '/img/site/ghost-pin.png',
                map: map
              });

              var customMarker = new CustomMarker(new google.maps.LatLng(lat, lng), map, { 'marker_id': '' + i.dataset.lat + i.dataset.lng + '' });

              mapMarkers[id] = customMarker;
            };
          });
        }, 555);
      });
    };

    // Remove all active states both for thumbs & for mapMarkers
    var removeActive = function removeActive() {
      console.log('Remove active');
      // Thumbs
      $ajaxZone.querySelectorAll('[data-place]').forEach(function (thumb) {
        thumb.classList.remove('is-active');
      });

      // Markers
      Object.keys(mapMarkers).forEach(function (key) {
        // console.log(key, mapMarkers[key]);
        mapMarkers[key].div.classList.remove('is-active');
      });
    };

    // Remove all active states both for thumbs & for mapMarkers
    var removeForceActive = function removeForceActive() {
      console.log('Remove active');
      // Thumbs
      $ajaxZone.querySelectorAll('[data-place]').forEach(function (thumb) {
        thumb.classList.remove('force-active');
      });

      // Markers
      Object.keys(mapMarkers).forEach(function (key) {
        // console.log(key, mapMarkers[key]);
        mapMarkers[key].div.classList.remove('force-active');
      });
    };

    // var boundschangedListener = google.maps.event.addListener(map, 'bounds_changed', function() { console.log('map bounds changed'); } );

    // Try HTML5 geolocation.
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     const pos = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude
    //     };

    //     var geoMarker = new google.maps.Marker({
    //       position: pos,
    //       map: map,
    //       icon: 'img/site/icon-geolocation.png',
    //     });

    //     mapMarkers.push(geoMarker);

    //     bounds = new google.maps.LatLngBounds();
    //     mapMarkers.forEach((marker) => {
    //       bounds.extend(marker.getPosition());
    //     });

    //     const newZoom = getBoundsZoomLevel(bounds, mapDim);
    //     map.setZoom(newZoom);
    //     map.setCenter(bounds.getCenter());

    //     console.log('GeoLocation succedded');
    //   }, () => {
    //     console.log('GeoLocation failed');
    //   });
    // } else {
    //   // Browser doesn't support Geolocation
    //   console.log('GeoLocation not supported');
    // }
  };

  // GEO ZONE


  var initGeolocation = function initGeolocation() {

    console.log('[GeolocationServices.js] initGeolocation();');

    if (navigator.geolocation) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(showPosition, showError, config);
    } else {
      tempLog('Geolocation is not supported in your browser');
    }
  };

  var showPosition = function showPosition(position) {

    // tempLog('Latitude: '+position.coords.latitude+'Longitude: '+position.coords.longitude);

    var $loader = '<div class="loader-container" style="height: 20px;"><div class="loader" style="width: 20px;"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div></div>';

    var userLat = position.coords.latitude;
    var userLong = position.coords.longitude;

    if (document.querySelector('[data-geo-distance]')) {
      var $businesses = [].slice.call(document.querySelectorAll('[data-geo-distance]')).forEach(function (elem, index) {

        elem.innerHTML = $loader;

        setTimeout(function () {

          if (parseFloat(elem.dataset.geoLat)) {

            var userLatLong = new google.maps.LatLng({ lat: userLat, lng: userLong });
            var businessLatLong = new google.maps.LatLng({ lat: parseFloat(elem.dataset.geoLat), lng: parseFloat(elem.dataset.geoLong) });

            var distance = google.maps.geometry.spherical.computeDistanceBetween(userLatLong, businessLatLong);

            if (Math.round(distance) >= 1000) {
              distance = Math.round(distance / 1000) + 'km';
            } else {
              distance = Math.round(distance) + ',';
            }

            elem.innerHTML = distance;
          }
        }, 250 * index);
      });

      setTimeout(function () {
        // orderOffersByDistance(position);
        orderLocationsByDistance(position);
      }, 250 * ([].slice.call(document.querySelectorAll('[data-geo-distance]')).length + 1));
    }

    // tempLog(`Lat = (${userLat}) & Lng = (${userLong}) checking for Lat = (45.50221500792518) & Lng = (-73.5558620095253) == ${distance}m`);

    return false;
  };

  var showError = function showError(error) {

    switch (error.code) {
      case error.PERMISSION_DENIED:
        tempLog("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        tempLog("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        tempLog("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        tempLog("An unknown error occurred.");
        break;
    }
  };

  var orderOffersByDistance = function orderOffersByDistance(position) {

    var unorderedLocations = {};
    var $locations = $('[data-places-offers] .single-place');
    var userLat = position.coords.latitude;
    var userLong = position.coords.longitude;

    [].slice.call($locations).forEach(function ($location) {
      var locLat = $location.getAttribute('data-lat');
      var locLng = $location.getAttribute('data-lng');
      // console.log(loc);

      var distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(userLat, userLong), new google.maps.LatLng(locLat, locLng));

      unorderedLocations[distance] = $location;
    });

    console.log(unorderedLocations);

    $('[data-places-offers]')[0].innerHTML = '';

    var unsorted = Object.keys(unorderedLocations),
        sorted = unsorted.sort(function (a, b) {
      return a - b;
    });

    console.log('sorted : ' + sorted);

    Object.keys(sorted).sort().forEach(function (key) {
      console.log(typeof key === 'undefined' ? 'undefined' : _typeof(key));
      console.log(unorderedLocations[key]);
      var $htmlElement = unorderedLocations[sorted[key]];
      // $htmlElement.querySelector('.map-distance').innerHTML = (sorted[key] / 1000).toFixed(1) + 'km';
      $('[data-places-offers]')[0].appendChild($htmlElement);
    });
  };

  var orderLocationsByDistance = function orderLocationsByDistance(position) {

    console.log("orderLocationsByDistance();", position);

    var unorderedLocations = {};
    var $locations = $('[data-place]');
    var userLat = position.coords.latitude;
    var userLong = position.coords.longitude;

    [].slice.call($locations).forEach(function ($location) {
      var locLat = $location.getAttribute('data-lat');
      var locLng = $location.getAttribute('data-lng');
      // console.log(loc);

      var distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(userLat, userLong), new google.maps.LatLng(locLat, locLng));

      unorderedLocations[distance] = $location;
    });

    console.log(unorderedLocations);

    $('[data-ajax-refresh]')[0].innerHTML = '';

    var unsorted = Object.keys(unorderedLocations),
        sorted = unsorted.sort(function (a, b) {
      return a - b;
    });

    console.log('sorted : ' + sorted);

    Object.keys(sorted).sort().forEach(function (key) {
      console.log(typeof key === 'undefined' ? 'undefined' : _typeof(key));
      console.log(unorderedLocations[key]);
      var $htmlElement = unorderedLocations[sorted[key]];
      // $htmlElement.querySelector('.map-distance').innerHTML = (sorted[key] / 1000).toFixed(1) + 'km';
      $('[data-ajax-refresh]')[0].appendChild($htmlElement);
    });
  };

  var orderLocationsByDistanceInput = function orderLocationsByDistanceInput(postalCode) {

    console.log("orderLocationsByDistanceInput();", postalCode);

    var unorderedLocations = {};
    var $locations = $('[data-place]');

    var $loader = '<div class="loader-container" style="height: 80px;"><div class="loader" style="width: 80px;"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div></div>';
    $('[data-ajax-refresh]')[0].innerHTML = $loader;

    fetch('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDNUD6wYgRb5h_GjCu9eS2DGGAuYRkMPEY&address=' + postalCode).then(function (resp) {
      return resp.json();
    }).then(function (data) {
      console.log("feth data", data);
      console.log(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng);

      var userLat = data.results[0].geometry.location.lat;
      var userLong = data.results[0].geometry.location.lng;

      [].slice.call($locations).forEach(function ($location) {
        var locLat = $location.getAttribute('data-lat');
        var locLng = $location.getAttribute('data-lng');
        // console.log(loc);

        var distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(userLat, userLong), new google.maps.LatLng(locLat, locLng));

        unorderedLocations[distance] = $location;
      });

      console.log(unorderedLocations);

      $('[data-ajax-refresh]')[0].innerHTML = '';

      var unsorted = Object.keys(unorderedLocations),
          sorted = unsorted.sort(function (a, b) {
        return a - b;
      });

      console.log('sorted : ' + sorted);

      Object.keys(sorted).sort().forEach(function (key) {
        console.log(typeof key === 'undefined' ? 'undefined' : _typeof(key));
        console.log(unorderedLocations[key]);
        var $htmlElement = unorderedLocations[sorted[key]];
        // $htmlElement.querySelector('.map-distance').innerHTML = (sorted[key] / 1000).toFixed(1) + 'km';
        $('[data-ajax-refresh]')[0].appendChild($htmlElement);
      });
    });
  };

  var tempLog = function tempLog(txt) {
    // document.querySelector('#geo-log').innerHTML = txt;
    console.log(txt);
  };

  // Expose public methods & properties
  return {
    init: init,
    initMap: initMap
  };
}();