'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.PriceRanging = function PriceRanging() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $ranges = [].slice.call(document.querySelectorAll("input[type='range']"));

    $ranges.forEach(function ($range) {
      onChangeInput($range);

      $range.addEventListener('input', function () {
        onChangeInput($range);
      });
    });

    return true;
  };

  var onChangeInput = function onChangeInput($range) {
    var $total = document.querySelector(".pricing002-total-price");
    var $ranges = [].slice.call(document.querySelectorAll("input[type='range']"));

    var item = $range.getAttribute("data-range");
    var $price = document.querySelector(".slider-price[data-price='" + item + "']");
    var $valuebar = document.querySelector(".value-bar[data-value-bar='" + item + "']");

    var value = $range.value;
    var total = 0;
    var barWidth = value / $range.getAttribute("max") * 100;
    $valuebar.style.width = barWidth.toString() + "%";

    for (var i = 0; i < $ranges.length; i++) {
      total += parseInt($ranges[i].value);
    }

    $price.innerHTML = value.toString();
    $total.innerHTML = total.toString() + "$";
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();